import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { CardTitle } from "reactstrap";
import { useSelector } from "react-redux";
import WindowedSelect from "react-windowed-select";
import { TimerContext } from "contexts/timer";

function FormTipoDocumento(props) {
  const base = useSelector((state) => state.base);
  const [selectOptionsBase, setSelectOptionsBase] = useState([]); //Parte 2*************
  const [optionsBase, setOptionsBase] = useState();
  const [optionsSisdepen, setOptionsSisdepen] = useState();
  const [mapeamentos, setMapeamentos] = useState([]);
  const [tipo, setTipo] = useState();
  const [id, setId] = useState();
  const [alertOk, setAlertOk] = useState(null);
  const [alertErr, setAlertErr] = useState(null);
  const [mensagem, setMensagem] = useState();
  const [controller, setController] = useState(false);
  const [loading, setLoading] = useState("hidden");
  const [time, setTime] = useState(500000);
  const { timer, setTimer } = useContext(TimerContext);

  const handleSelectChangeSisdepen = (optionSisdepen) => {
    setId(optionSisdepen.value);
  };

  const handleSelectChangeBase = (optionBase) => {
    setTipo(optionBase.value);
  };

  function getDescricaoFromSisdepen() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES + "v1/documentos/tipos",
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        setLoading("");
        return response.json();
      })
      .then(function (data) {
        const optionSisdepen = [{}];
        if (data != null) {
          data.forEach((item) =>
            optionSisdepen.push({
              value: item.id,
              label: item.descricao,
            })
          );
        }
        setLoading("");
        setOptionsSisdepen(optionSisdepen);
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function getDescricaoFromBase() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/integradores/tipo-documento/base/" +
        base,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        setLoading("");
        return response.json();
      })
      .then(function (data) {
        const optionBase = [];
        if (data != null && data.status != 500 && data.status != 501) {
          data.forEach((item) => {
            if (item != null) {
              optionBase.push({
                value: item,
                label: item,
              });
            }else{
              optionBase.push({
                value: "NI",
                label: "Não informado",
              });
            }
          });
          setLoading("");
          setOptionsBase(optionBase);
          setMensagem("Caixas de seleção atualizadas.");
          showAlertOk();
        } else {
          if (data.status == 501)
            setMensagem("Error: " + data.status + " - " + data.detail);
          else if (data.status == 500)
            setMensagem("Error: " + data.status + " - " + data.error);

          setOptionsBase();
          showAlertError();
        }
      })
      .catch(function (error) {
        setOptionsBase();
        setMensagem(error.toString());
        showAlertError();
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function getMapeamento() {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/converter/documentos/tipos/base/" +
        base,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        setLoading("");
        return response.json();
      })
      .then(function (data) {
        setLoading("");
        setMapeamentos(data);
        console.log("FUNCIONANDO...")
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      })
      .finally(function (data) {
        setLoading("hidden");
      });
  }

  function postMapeamento() {
    if (base != null && tipo != null && id != null) {
      const postRequest = {
        base: base.toString(),
        descricao: tipo.toString(),
        tipoSisdepen: {
          id: id.toString(),
        },
      };

      return fetch(
        process.env.REACT_APP_URL_API_INTEGRADORES +
          "v1/converter/documentos/tipos",
        {
          mode: "cors",
          credentials: "include",
          method: "POST",
          body: JSON.stringify(postRequest),
          headers: new Headers({
            authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
            "content-type": "application/json",
          }),
        }
      )
        .then(function (response) {
          if (response.ok) {
            setMensagem("Mapeamento criado com sucesso.");
            showAlertOk();
            console.log(response.json); //testando
          } else {
            setMensagem("Error: Mapeamento já existente.");
            showAlertError();
          }
          setController(!controller);
        })
        .catch(function (error) {
          setMensagem(error.toString());
          showAlertError();
        });
    } else {
      setMensagem(
        "Error: Campo Base, Descrição (Estado) ou Descrição (Sisdepen) estão vazios."
      );
      showAlertError();
    }
  }

  function deleteMapeamento(e) {
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/converter/documentos/tipos/" +
        e.target.value,
      {
        mode: "cors",
        credentials: "include",
        method: "DELETE",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        setMensagem("Mapeamento excluído com sucesso.");
        showAlertOk();
        setController(!controller);
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
        console.log(error);
      });
  }

  function updateTableByMapeamento() {
    setMensagem("Mapeamento de tipo-documento da base " + base + " iniciado.");
    showAlertOk();
    return fetch(
      process.env.REACT_APP_URL_API_INTEGRADORES +
        "v1/converter/documentos/tipos/atualizar-mapeamentos-tipo-documento/" +
        base,
      {
        mode: "cors",
        credentials: "include",
        method: "GET",
        headers: new Headers({
          authorization: process.env.REACT_APP_GERENCIAMENTO_KEY,
          "content-type": "application/json",
        }),
      }
    )
      .then(function (response) {
        if (response.ok == true) {
          setMensagem(
            "Mapeamento de tipo-documento da base " + base + " finalizado."
          );
          showAlertOk();
        } else {
          setMensagem(
            "Error: " +
              response.status +
              " - " +
              "Não foi possível realizar a atualização das tabelas."
          );
          showAlertError();
        }
      })
      .catch(function (error) {
        setMensagem(error.toString());
        showAlertError();
      });
  }

  function showAlertOk() {
    setAlertOk(true);
    setTimeout(function () {
      setAlertOk(false);
    }, 2000);
  }

  function showAlertError() {
    setAlertErr(true);
    setTimeout(function () {
      setAlertErr(false);
    }, 2000);
  }

  useEffect(() => {
    getMapeamento();
  }, [base, controller]);

  useEffect(() => {
    getDescricaoFromBase();
  }, [base]);

  useEffect(() => {
    getDescricaoFromBase();
    getDescricaoFromSisdepen();
  }, [props.tipo]);

  //parte*************
  useEffect(() => {
    var temp = optionsBase?.filter((item) => {
      var control = true;
      for (let i = 0; mapeamentos?.length > 0 && i < mapeamentos?.length; i++) {
        if (mapeamentos[i].descricao == item.value) control = false;
      }
      return control;
    });
    setSelectOptionsBase(temp);
  }, [optionsBase, mapeamentos]);

  useEffect(()=>{
    if((selectOptionsBase != [] && alertOk != null) || (selectOptionsBase != [] && alertErr != null)){
      setLoading("hidden");
    }else{
      setLoading("");
    }
  });

  useEffect(() => {
    clearInterval(timer);
    setTimer(setInterval(getMapeamento, time)); 
    console.log(time);      
  }, [time]);



  return (
  <div>  
    <div>
      <Row>
        <Col>
          <Card style={{ padding: "10px", width:"94%", marginLeft:"35px" }} align="center" >
            <strong>
            Selecione a taxa de atualização da tabela:
            </strong>
            <div>
            <select
            style={{ width: 100, height: 23 }}
            onChange={(event) => setTime(event.target.value)}
            >
            <option>Selecione</option>
            <option value={5000}>5</option>
            <option value={20000}>20</option>
            <option value={30000}>30</option>
            </select>
            <strong> segundos</strong>
            </div>
          </Card>  
        </Col>
      </Row>
    </div>  
    {time !=  500000 ? ( 
    <div>  
      <Spinner
        style={{ visibility: loading }}
        animation="border"
        role="status"
      ></Spinner>
      <Alert style={{ backgroundColor: "green" }} show={alertOk}>
        <p>{mensagem}</p>
      </Alert>
      <Alert variant="danger" show={alertErr}>
        <p>{mensagem}</p>
      </Alert>
      <Card border="dark">
        <CardTitle style={{ padding: "5px" }} align="center">
          <strong>Mapeamento tipo-documento</strong>
        </CardTitle>
        <hr></hr>
        <Form style={{ padding: "10px" }}>
          <Form.Group className="mb-3" controlId="selectTipo">
            <Form.Label>Descrição (SISDEPEN)</Form.Label>
            <WindowedSelect
              isClearable={true}
              options={optionsSisdepen}
              onChange={handleSelectChangeSisdepen}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Descrição (Estado)</Form.Label>
            <WindowedSelect
              isClearable={true}
              options={selectOptionsBase} //PArte**********
              onChange={handleSelectChangeBase}
            />
          </Form.Group>
          <Button
            onClick={() => {
              postMapeamento();
            }}
            size="sm"
            variant="primary"
          >
            Mapear
          </Button>
          <Button onClick={updateTableByMapeamento} size="sm" variant="primary">
            Atualizar tabelas
          </Button>
        </Form>
      </Card>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>base</th>
            <th>descrição_estado</th>
            <th>descrição_sisdepen</th>
            <th>quantidade</th>            
            <th>created_at</th>
            <th>updated_at</th>
            <th>excluir</th>
            <th>não_mapeados</th>
          </tr>
        </thead>
        <tbody>
          {mapeamentos.map((mapeamento,i) => (
            <tr>
              <td>{mapeamento.id}</td>
              <td>{mapeamento.base}</td>
              <td>{mapeamento.descricao}</td>
              <td>{mapeamento.tipoSisdepen.descricao}</td>
              <td>{mapeamento.quantidade}</td>              
              <td>{new Date(mapeamento.createdAt).toLocaleString("pt-BR")}</td>
              <td>{new Date(mapeamento.updatedAt).toLocaleString("pt-BR")}</td>
              <td>
                <Button
                  value={mapeamento.id}
                  onClick={(event) => deleteMapeamento(event)}
                  variant="danger"
                >
                  Excluir
                </Button>
              </td>
              {i===0 ? <td rowspan="0">{mapeamento.naoMapeados} </td> : null}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    ) : null}
  </div>  
  );
}

export default FormTipoDocumento;
